<template>
	<!-- form dialog -->
	<v-dialog
		v-model='show'
		max-width='500px'
		width='500px'
		@keydown.enter='onSubmit()'
		@keydown.esc='close()'
		@click:outside='close()'
	>
		<v-card>
			<v-form ref='form'>
				<v-card-title>
					<span class='headline'>Xarajat kiritish</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>

							<v-col cols='12'>
								<v-text-field
									v-model='formData.amount'
									label='SUMMA'
									type='number'
									dense
									outlined
									hide-details
								></v-text-field>
							</v-col>

							<v-col cols='12'>
								<v-textarea
									v-model='formData.from'
									label='IZOH'
									dense
									outlined
									hide-details
									required
									height='80px'
								>
								</v-textarea>
							</v-col>

							<v-col cols='12'>
								<v-menu
									v-model='isDate'
									:close-on-content-click='false'
									offset-y
									min-width='auto'
								>
									<template v-slot:activator='{ on, attrs }'>
										<v-text-field
											v-model='formData.date'
											class='my-date-picker'
											readonly
											v-bind='attrs'
											hide-details
											outlined
											:append-icon='icons.mdiCalendar'
											:rules='[required]'
											v-on='on'
										></v-text-field>
									</template>
									<v-date-picker
										v-model='formData.date'
										color='primary'
										no-title
										:first-day-of-week='1'
										locale='ru-ru'
										@input='isDate = false'
									></v-date-picker>
								</v-menu>
							</v-col>

							<v-col cols='12'>
								<v-autocomplete
									v-model='formData.cashbox_id'
									:items='selectsDatas.cashbox'
									item-text='name'
									item-value='id'
									label='KASSA'

									dense
									outlined
									hide-details
									clearable
									:rules='selectRule'
								>
								</v-autocomplete>
							</v-col>

							<v-col cols='6' v-if='place_id == null'>
								<v-autocomplete
									v-model='formData.place_id'
									:items='places'
									item-text='name'
									item-value='id'
									label='FILIAL'
									dense
									outlined
									hide-details
									clearable
								>
								</v-autocomplete>
							</v-col>

							<v-col cols='12'>
								<v-autocomplete
									v-model='formData.currency_id'
									:items='selectsDatas.currency'
									item-text='name'
									item-value='id'
									label='VALYUTA'
									dense
									outlined
									hide-details
									clearable
									:rules='selectRule'
								>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color='gray'
						outlined
						@click='close()'
					>
						Bekor qilish
					</v-btn>
					<v-btn
						color='success'
						type='button'
						@click='onSubmit'
						:disabled='submitDisabled'
					>
						<v-icon
							class='loading-animation'
							v-if='submitDisabled'
						>
							{{ icons.mdiLoading }}
						</v-icon>
						Saqlash
					</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import { mdiCalendar, mdiPlusCircleOutline } from '@mdi/js'

import axios from '@axios'

import { computed, ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import IncomeStoreModule from './IncomeStoreModule'
import store from '@/store'
import Button from '../../components/button/Button'

const MODULE_NAME = 'income'

export default {
	components: { Button },

	// props: {
	//
	// },
	created() {
		this.loadCurrency()
		this.loadCashbox()
	},
	setup(props, { emit }) {
		// Register module
		if (!store.hasModule(MODULE_NAME)) {
			store.registerModule(MODULE_NAME, IncomeStoreModule)
		}

		const branch_id = computed(() => store.state.branch_id)
		watch(branch_id, val => {
			emptyFormData.place_id = val
			formData.value.place_id = val
		})

		// show, hide
		const emptyFormData = {
			id: null,
			place_id: branch_id.value,
			date: new Date().toISOString().substr(0, 10),
			amount: null,
			currency_id: 1,
			from: null,
			cashbox_id: 1,
		}
		const formData = ref({ ...emptyFormData })
		const form = ref(null)
		const show = ref(false)
		const picker = new Date().toISOString().substr(0, 10)
		const isDate = ref(false)
		const open = (id = null) => {
			show.value = true
			if (id) {
				const newFormData = JSON.parse(JSON.stringify(store.getters[`${MODULE_NAME}/getById`](id)))
				formData.value = {
					...newFormData,
					amount: String(newFormData.amount),
				}
			}
		}
		const close = () => {
			show.value = false
			form.value.resetValidation()
			formData.value = { ...emptyFormData }
		}

		const places = ref([])
		const loadPlaces = () => {
			axios.get('/api/places').then(response => {
				places.value = response.data.data
			})
		}
		loadPlaces()

		// validation
		const selectRule = [v => !!v || 'Biron qiymatni tanlang!']

		// on form submit
		const submitDisabled = ref(false)
		const onSubmit = () => {

			if (submitDisabled.value === true)
				return
			else
				submitDisabled.value = true

			submitDisabled.value = true

			if (!form.value.validate()) {
				console.log('form inputs not valid!')

				submitDisabled.value = false
				return
			}

			if (form.value.validate()) {
				if (formData.value.id) {
					store
						.dispatch(`${MODULE_NAME}/updateRow`, formData.value)
						.then(({ message }) => {
							close()

							// emit('notify', { type: 'success', text: message })
						})
						.catch(error => {
							console.log(error)
							emit('notify', { type: 'error', text: error.message })
						})
						.finally(() => {
							submitDisabled.value = false
						})
				} else {
					store
						.dispatch(`${MODULE_NAME}/addRow`, formData.value)
						.then(({ message }) => {
							close()
							emit('notify', { type: 'success', text: message })
						})
						.catch(error => {
							console.log(error)
							emit('notify', { type: 'error', text: error.message })
						})
						.finally(() => {
							submitDisabled.value = false
						})
				}
			}
		}

		const selectsDatas = ref({})

		// ! METHODS
		const loadCurrency = () => {
			axios
				.get('/api/currencies', { params: { itemsPerPage: -1 } })
				.then(response => {
					if (response.data.success) {
						selectsDatas.value.currency = response.data.data
					}
				})
				.catch(error => console.log(error))
		}
		const loadCashbox = () => {
			axios
				.get('/api/cashboxes', { params: { itemsPerPage: -1 } })
				.then(response => {
					if (response.data.success) {
						selectsDatas.value.cashbox = response.data.data
					}
				})
				.catch(error => console.log(error))
		}

		// Currency
		const currencyForm = ref(null)
		const addCurrency = (id = null) => {
			currencyForm.value.open(id)
		}
		const addCurrencyToOptions = row => {
			selectsDatas.value.currency = selectsDatas.value.currency.concat([row])
			formData.value.currency_id = row.id
		}

		// Cashbox
		const cashboxForm = ref(null)
		const addCashbox = (id = null) => {
			cashboxForm.value.open(id)
		}
		const addCashboxToOptions = row => {
			selectsDatas.value.cashbox = selectsDatas.value.cashbox.concat([row])
			formData.value.cashbox_id = row.id
		}

		return {
			form,
			picker,
			isDate,
			required,
			formData,
			selectRule,
			show,
			onSubmit,
			submitDisabled,
			open,
			close,

			places,
			place_id: branch_id,

			selectsDatas,
			loadCurrency,
			loadCashbox,
			currencyForm,
			addCurrency,
			addCurrencyToOptions,
			cashboxForm,
			addCashbox,
			addCashboxToOptions,

			icons: {
				mdiPlusCircleOutline,
				mdiCalendar,
			},
		}
	},
}
</script>

<style>
.v-input__append-outer {
	margin: 0 0 0 10px !important;
}

.btn-dialog-add-item {
	min-width: 60px !important;
	padding-right: 15px !important;
	padding-left: 15px !important;
	border-color: rgba(94, 86, 105, 0.15) !important;
}
</style>
